// index.ts 文件
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import taskBoardSlice from "@/store/modules/taskBoardSlice";
import userSlice from "@/store/modules/userSlice";
import meterialScience from "@/store/modules/meterialScience";
import bpmn from "@/store/modules/bpmnSlice";
import themeSlice from "@/store/modules/themeSlice";
import commonSlice from "@/store/modules/commonSlice";
// configureStore创建一个redux数据
export const store = configureStore({
  // 合并多个Slice
  reducer: {
    theme: themeSlice,
    taskBoard: taskBoardSlice,
    bpmn: bpmn,
    user: userSlice,
    meterial: meterialScience,
    common: commonSlice,
  }
});

// 配置configureStore, ThunkAction, Action
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;