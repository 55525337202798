import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post, get } from '@/service/request';
import { getUrlParms } from '@/utils';
// import { Obj } from '_reselect@4.1.6@reselect/es/types';
/**
 * 用户信息
 */

export interface state {
  "barLine": any[],
  "pie": any[],
  "tableData": Object,
  "tableList": any[],
  "price": number,
  "search": string
  "category": any[],
  "typeSelect": string,
  "form": any,
}
// 初始值
const initialState: state = {
  "barLine": [],
  "pie": [],
  "tableData": {},
  "tableList": [],
  "price": 0,
  "search": '',
  "category": [],
  "typeSelect": 'total',
  "form": {
    "projectId": getUrlParms("projectId"),
    "typeSelect": '',
    "type": '',
    "size": 20,
    "current": 1,
    "name": '',
    "ascs": '',
    "descs": ''
  }
}
// 材料成本top10
export const getResultData = (payload: any) => (dispatch: any) => {
  //更新请求参数
  // dispatch(setResultParams(payload));
  // 查询图表
  get("/justtruth-cost/materialCost/select", payload).then((res: any) => {
    if (res.success) {
      console.log(payload);
      dispatch(setPrice(res.data.totalPrice));//设置结果页数据
      dispatch(setResultData(res.data.materialCostTopVOs));//设置结果页数据
      dispatch(setResultDataPie(res.data.materialTypeTopVOs));//设置结果页数据
    }
  })
};
//   查询分页记录
export const getResultDataPage = (payload: any) => (dispatch: any) => {
  //更新请求参数
  // dispatch(setResultParams(payload));
  // 查询图表
  dispatch(setForm(payload))
  get("/justtruth-cost/materialCost/selectPage", payload).then((res: any) => {
    if (res.success) {
      dispatch(setTableData({ data: res.data, current: payload.current }));//设置表格数数据
    }
  })
};

// 创建一个 Slice（切片）
export const slice = createSlice({
  // 命名空间，name会作为action type的前缀
  name: 'userInfo',
  // 初始化状态
  initialState,
  // 1、定义reducer更新状态的函数
  // 2、组件中dispatch使用的action函数
  reducers: {
    setPrice(state, { payload }) {
      state.price = payload;
    },
    //材料成本top10
    setResultData(state, { payload }) {
      state.barLine = payload;
    },
    // 材料成本类别分布top10
    setResultDataPie(state, { payload }) {
      state.pie = payload;
    },
    setTableData(state, { payload }) {
      state.tableData = payload.data;
      state.tableList = payload.data;
      
    },
    setSearchName(state, { payload }) {
      state.search = payload;
      console.log(payload);

    },
    setForm(state, { payload }) {
      state.form = payload;
    },
    setCategoryName(state, { payload }) {
      state.category = payload;
    },
    setTypeSelectName(state, { payload }) {
      state.typeSelect = payload;
    }
  },
  // extraReducers 字段让 slice 可以处理在别处定义的 actions， 
  // 包括由 createAsyncThunk或其他slice生成的actions。
  extraReducers: (builder) => { }
});


// 导出action函数
export const { setPrice,setForm, setTypeSelectName, setSearchName, setCategoryName, setResultData, setResultDataPie, setTableData } = slice.actions;
// 导出reducer，创建store
export default slice.reducer;