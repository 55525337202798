import { createSlice } from '@reduxjs/toolkit';

/**
 * 项目公共属性
 */

export interface commonState {
    "isMobile": boolean //是否是移动端
}
// 初始值
const initialState: commonState = {
    "isMobile": false
}



// 创建一个 Slice（切片）
export const slice = createSlice({
    // 命名空间，name会作为action type的前缀
    name: 'index',
    // 初始化状态
    initialState,
    // 1、定义reducer更新状态的函数
    // 2、组件中dispatch使用的action函数
    reducers: {
        setIsMobile(state, { payload }) {
            state.isMobile = payload;
        }
    },
    // extraReducers 字段让 slice 可以处理在别处定义的 actions， 
    // 包括由 createAsyncThunk或其他slice生成的actions。
    extraReducers: (builder) => { }
});


// 导出action函数
export const { setIsMobile } = slice.actions;
// 导出reducer，创建store
export default slice.reducer;