/**
 * 网络请求配置
 */
import ReactDOM from 'react-dom';
import {message, Spin} from "antd";
import {ModalFun} from '@/components/AntDraggableModal';
import axios from "axios";
import {getUrlParms} from "@/utils"
import {getUserInfo, setUserInfo, clearAllCache} from "@/utils/cache"
import {getStorage, setStorage, clearAllStorage} from "@/utils/storage"
import {getSession, setSession, clearAllSession} from '@/utils/session';
import {getProjectMenuTree, getMenuTree} from '@/utils/menuTree';
import {
    CloseCircleOutlined
} from '@ant-design/icons';
// 超时时间1分钟（毫秒级）
axios.defaults.timeout = 60000;
// 反向代理别名
axios.defaults.baseURL = "/api";
// 当前正在请求的数量
let requestCount = 0;
// 获取文件流接口
let fileStream = [
    '/justtruth-quota/quotafile/getview'
]

// 显示loading
export function showLoading() {
    // requestCount为0，才创建loading, 避免重复创建
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.render(<Spin tip="Loading..." size="default"/>, dom)
    }
    requestCount++
}

// 隐藏loading
export function hideLoading() {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}

// 防抖
let timer = null; // 通过闭包来保存timer的值
export function debounce(callback, duration) {
    if (timer) {
        // 只是clear，此时的timer还是有值的，为该定时器的id，整形
        clearTimeout(timer)
    }
    let excuteNow = !timer
    timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
    }, duration)
    if (excuteNow) {
        callback()
    }
}

/**
 * http request 拦截器
 */
let cancel;
axios.interceptors.request.use(
    (config) => {
        config.headers = {
            "Content-Type": "application/json",
            "Authorization": "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
            ...config.head
        };
        // 是否展示loading加载框
        if (config.headers.isLoading !== false) {
            showLoading()
        }
        // 将当前请求所对应的取消函数存入缓存
        config.cancelToken = new axios.CancelToken(function executor(c) {
            cancel = c;
        });
        /**
         * 场景：
         * 同一浏览器，同一账号打开两个窗口，其中一个窗口退出登录，另个窗口登录新账号，上个窗口的session是旧数据。故中断请求，替换数据，强制刷新页面。
         */
        if (getSession("userSession") && getStorage("userStorage") && getSession("userSession").userId != getStorage("userStorage").userId) {
            hideLoading()
            cancel();
            setSession("userSession", getStorage("userStorage"));
            window.location.reload();
        }
        // 获取文件流接口设置数据返回类型
        if (fileStream.indexOf(config.url) != -1) {
            config.responseType = 'blob';
        }

        const userInfo = getUserInfo();
        // 构建head请求参数
        if (userInfo) {
            // 构建token参数
            config.headers["blade-auth"] = "bearer " + userInfo.accessToken;
            // 构建团队id参数(优先使用head传递的团队id)
            config.headers["tenant-id"] = userInfo.tenantId;
        }
        // 构建项目参数（在“我的项目”模块下的页面调用接口，需要传递“项目id”）
        if (userInfo && window.location.pathname.indexOf("/project") == 0 && !config.headers["project-id"]) {
            config.headers["project-id"] = getUrlParms("projectId");
        }
        return config;
    },
    (error) => {
        // 判断当前请求是否设置了不显示Loading
        if (error.config.headers.isLoading !== false) {
            hideLoading()
        }
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */

axios.interceptors.response.use(
    (response) => {
        // 判断当前请求是否设置了不显示Loading
        if (response.config.headers.isLoading !== false) {
            hideLoading()
        }
        return Promise.resolve(response);
    },
    (error) => {
        if (error.config.headers.isLoading !== false) {
            hideLoading()
        }
        console.log("---请求失败---", error.response)
        if (error.response && error.response.status) {
            switch (error.response.status) {
                // 400：请求异常
                case 400:
                    debounce(() => {
                        message.error(error.response.data.msg);
                    }, 1000)
                    return Promise.reject(error.response);
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    return loginInvalid(error);
                // 403请求未授权
                case 403:
                    try {
                        let result = JSON.parse(error.response.data.msg);
                        console.log(result)
                        authHandle(result);
                    } catch (error) {
                        console.log("------403：字符串解析异常------", error.response)
                    }
                    return Promise.reject(error.response);
                // 404请求不存在
                case 404:
                    debounce(() => {
                        message.warning("网络请求不存在");
                    }, 1000)
                    return Promise.reject(error.response);
                // 500服务器异常
                case 500:
                    debounce(() => {
                        message.error("服务器异常");
                    }, 1000)
                    return Promise.reject(error.response);
                // 503服务器不可用
                case 503:
                    debounce(() => {
                        message.error("服务器不可用");
                    }, 1000)
                    return Promise.reject(error.response);
                // 其他错误，直接抛出错误提示
                default:
                    debounce(() => {
                        message.error(error.response.data.msg || error.response.statusText);
                    }, 1000)
                    return Promise.reject(error.response);
            }
        }
    }
);

/**
 * 重新发送请求
 * @param  httpConfig 请信息
 * @returns
 */
function reRequest(config, callback) {
    let {method, url} = config;
    let obj = {
        params: config.params || {},
        head: config.head || {}
    }
    if (method == "post") {
        try {
            obj = JSON.parse(config.data);
        } catch (error) {
            console.error("-----重新请求POST参数异常------", config.data)
            obj = config.data;
        }
    }
    console.log("--------重新请求res------", obj)
    axios[method](url, obj).then(res => {
        callback(res);
    }).catch(err => {
        callback(err);
    })
}

/**
 * 401未登录
 * 未登录则跳转登录页面，并携带当前页面的路径
 * 在登录成功后返回当前页面，这一步需要在登录页操作。
 * @param error 请求返回数据
 * @returns
 */
function loginInvalid(error) {
    return new Promise(function (resolve, reject) {
        let userInfo = getUserInfo();

        // 重置状态
        function resetStatus(error) {
            clearAllStorage();
            clearAllSession();
            let urls = window.location.pathname + window.location.search
            setStorage("goBackUrl", urls)
            window.location.href = '/';
            reject(error);
        }

        if (userInfo) {
            // 刷新用户token
            axios.get('/blade-auth/refresh_token?refreshToken=' + userInfo.refreshToken).then((tokenRes) => {
                if (tokenRes.data.success) {
                    setUserInfo(tokenRes.data.data);
                    // token刷新成功 重新请求该接口
                    reRequest(error.config, (callback) => {
                        resolve(callback);
                    });
                } else {
                    resetStatus(tokenRes);
                }
            }).catch((tokenErr) => {
                resetStatus(tokenErr);
            });
        } else {
            resetStatus(error);
        }
    });
}

/**
 * 403权限异常
 * @param  result 权限状态数据
 */
function authHandle(result) {
    switch (result.code) {
        case "1": //1.不是该项目成员
            window.location.href = `/jurisdiction?code=${result.code}&admin=${result.admin}&projectName=${result.project}`
            break;
        case "2":
        case "3": // 2.未加入团队  3.团队已解散/注销
            window.location.href = `/jurisdiction?code=${result.code}&admin=${result.admin}`
            break;
        case "4": // 4.当前账号已被注销
            clearAllCache();
            window.location.href = `/jurisdiction?code=${result.code}&admin=${result.admin}`
            break;
        case "5": // 5.项目已被注销
            window.location.href = `/jurisdiction?code=${result.code}&admin=${result.admin}`
            break;
        case "6": // 6.是该项目成员 没有权限
            debounce(() => {
                let modal = ModalFun.confirm({
                    width: 420,
                    maskClosable: false,
                    centered: true,
                    closable: false,
                    title: "权限异常",
                    onOk: () => {
                        modal.hide();
                        reloadPage();
                    },
                    content: <div style={{marginTop: "20px"}}>
                        <CloseCircleOutlined style={{fontSize: "18px", color: "#F71519", marginRight: "5px"}}/>
                        <span>您没有相关权限，请联系管理员“</span> <span style={{color: '#2558E9'}}>{result.admin}</span>
                        <span>”开通权限</span>
                    </div>,
                    okText: '我知道了',
                    okButtonProps: {style: {marginRight: '0px'}},
                    cancelButtonProps: {style: {display: 'none'}}
                });
            }, 1000);
            // 刷新页面
            const reloadPage = () => {
                let routeIsBe = false;//当前路由权限菜单是否存在
                // 递归获取当前路由权限
                function getAccess(list) {
                    list.filter((item) => {
                        if (item.children && item.children.length > 0) {
                            getAccess(item.children);
                        } else if (item.path == window.location.pathname) {
                            routeIsBe = true;
                            return true;
                        }
                    });
                }

                // debugger
                if (window.location.pathname.includes('/bim')) {
                    console.log(1111);
                    getMenuTree((data) => {
                        getAccess(data || []);
                        console.log(data);
                        console.log(routeIsBe);

                        // 存在当前路由权限菜单，刷新当前页
                        if (routeIsBe) {
                            window.location.reload();
                            // return
                        } else if (data.length > 0) {
                            console.log(data);
                            let list = data[0];
                            let path = list.children && list.children.length > 0 ? list.children[0].path : list.path;
                            window.location.href = `${path}`
                            // return
                        } else {
                            // 路由权限菜单为空 返回首页
                            // if(data.length==0) return
                            window.location.href = '/'
                        }
                    });
                } else {
                    getProjectMenuTree(getUrlParms("projectId"), (data) => {
                        console.log(data, '------------');
                        getAccess(data || []);
                        console.log(routeIsBe);
                        // 存在当前路由权限菜单，刷新当前页
                        if (routeIsBe) {
                            console.log(routeIsBe);
                            window.location.reload();
                            return
                        }
                        // 不存在当前路由权限菜单，切换到第一个路由权限菜单
                        else if (data.length > 0) {
                            console.log(data);
                            let list = data[0];
                            let path = list.children && list.children.length > 0 ? list.children[0].path : list.path;
                            window.location.href = `${path}?projectId=${getUrlParms("projectId")}&projectName=${getUrlParms("projectName")}`
                            setSession("projectIsBack", "false");
                            return
                        }
                        // 路由权限菜单为空 返回首页
                        else {
                            window.location.href = '/bim/message'
                        }
                    });
                }
            }
            break;
        case "7": // 7.未加入任何团队
            window.location.href = `/jurisdiction?code=${result.code}`
            break;
    }
}

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @param head  header参数
 * @returns {Promise}
 */
export function get(url, params = {}, head = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                head: head
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param head  header参数
 * @param params
 * @returns {Promise}
 */

export function post(url, data, head = {}, params = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            params: params,
            head: head
        }).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}, head = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data, {head}).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}
