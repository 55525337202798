import { createSlice } from '@reduxjs/toolkit';

/**
 * 用户信息
 */

export interface state {
    "userState": any,//用户信息
    "agencyNum": any,//待办项徽标数
    "beforeunloadShow": boolean, //取消保存新前提示窗
}
// 初始值
const initialState: state = {
    "userState": null,
    "agencyNum": {
        total: 0, //总数
        suspend: 0, //待办项
        todo: 0, //挂起项
    },
    "beforeunloadShow": false,
}



// 创建一个 Slice（切片）
export const slice = createSlice({
    // 命名空间，name会作为action type的前缀
    name: 'userInfo',
    // 初始化状态
    initialState,
    // 1、定义reducer更新状态的函数
    // 2、组件中dispatch使用的action函数
    reducers: {
        setUserState(state, { payload }) {
            state.userState = payload;
        },
        setBeforeunloadShow(state, { payload }) {
            state.beforeunloadShow = payload;
        },
        setAgencyNum(state, { payload }) {
            state.agencyNum = {
                ...state.agencyNum,
                ...payload
            };
        },
    },
    // extraReducers 字段让 slice 可以处理在别处定义的 actions， 
    // 包括由 createAsyncThunk或其他slice生成的actions。
    extraReducers: (builder) => { }
});


// 导出action函数
export const { setUserState, setBeforeunloadShow,setAgencyNum } = slice.actions;
// 导出reducer，创建store
export default slice.reducer;