/**
 * 根据Key 查询缓存
 * @param {String} {key} 主键
 * @returns {any}
 */
function getStorage(key: string) {
    let value: string = window.localStorage.getItem(key) || "";
    try {
        return JSON.parse(value)
    } catch (err) {
        return value
    }
}

/**
 * 设置localStorage 内容
 * @param {String} {key} 主键
 * @param {any} {value} 内容
 * @returns {any}
 */
function setStorage(key: string, value: any) {
    const result = (typeof value == 'object' ? JSON.stringify(value) : value.toString());
    window.localStorage.setItem(key, result);
}

/**
 * 根据Key 清除缓存, 可传多个key值
 * @param {any} {key} 主键
 * @returns {Object}
 */
function clearStorage(key: string) {
    window.localStorage.removeItem(key);
}

/**
 * 清除所有缓存
 * @returns {undefined}
 */
function clearAllStorage() {
    // 获取所有键名
    const keys = Object.keys(window.localStorage);

    // 遍历并删除不需要的缓存
    keys.forEach(key => {
        if (key !== "taskColumns") {
            clearStorage(key);
        }
    });

}


export {
    setStorage,
    getStorage,
    clearStorage,
    clearAllStorage
}