import { post } from "@/service/request";
import { store } from '@/store/index';
import { reqParams, setTableTreeData, setResultParams } from "@/store/modules/taskBoardSlice";

// 展开至xx级
export const expandLevel = (resultParams: reqParams, regionType: string) => {
    post("/justtruth-region-task/board/task_details/tree_node/open_level", {
        ...resultParams,
        regionType
    }).then((res) => {
        if (res.success) {
            // 展开项
            let expandedKeys = getExpandAndShow(res.data, regionType);
            // 更新表格树数据
            store.dispatch(setTableTreeData(res.data));
            console.log("----展开至xxx----",res.data, expandedKeys)
            // 更新展开项参数数据
            store.dispatch(setResultParams({
                ...resultParams,
                regionExpanded: expandedKeys
            }))
        }
    })
};
// 获取展开项
export const getExpandAndShow = (list: any[], regionType: string) => {
    // 展开项
    let expandedKeys: string[] = [];
    /**
     * 递归获取展示项
     * @param list 表格数据列表
     */
    function getExpandKeys(list: any[]) {
        list.forEach((item) => {
            // 展开项
            if (item.title == "全部" || computeLevel(item.code) != regionType) {
                expandedKeys.push(item.code)
            }
            if (item.children && item.children.length > 0 && computeLevel(item.code) != regionType) {
                getExpandKeys(item.children)
            }
        })
    }
    getExpandKeys(list);
    return expandedKeys;
}

// 获取父级项
export const getParentCode = (list: any[], id: string) => {
    // 父级项
    let parentInfo: any = null
    /**
     * 递归获取父级项
     * @param list 表格数据列表
     */
    function getParentInfo(list: any[]) {
        list.forEach((item) => {
            // 展开项
            if (item.id != id) {
                parentInfo = item;
            }
            if (item.children && item.children.length > 0 && item.id != id) {
                getParentInfo(item.children)
            }
        })
    }
    getParentInfo(list);
    return parentInfo;
}

// 根据code计算当前层级
export const computeLevel = (code: string) => {
    let long = code.split("_").length;
    switch (long) {
        case 1:
            return "building"
        case 2:
            return "buildingUnit"
        case 3:
            return "floor"
        case 4:
            return "roomNo"
        default:
            return "";
    }
}