import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FLOWABLE_PREFIX } from '@/bpmn/constant/constants';

// 定义redux属性的名称和类型
export interface state {
  // 流程引擎前缀
  prefix: any;
  // 流程id
  processId: any;
  // 流程名称
  processName: any;
  // 流程信息
  processInfo: any;
}

// 定义redux属性的默认值
const initialState: state = {
  prefix: FLOWABLE_PREFIX,
  processId: undefined,
  processName: undefined,
  processInfo: null
};

/**
 * 定义修改redux属性的方法
 */
export const bpmnSlice = createSlice({
  name: 'bpmn',
  initialState,
  reducers: {
    handlePrefix: (state, action: PayloadAction<string>) => {
      state.prefix = action.payload;
    },
    handleProcessId: (state, action: PayloadAction<string | undefined>) => {
      state.processId = action.payload || undefined;
    },
    handleProcessName: (state, action: PayloadAction<string | undefined>) => {
      state.processName = action.payload || undefined;
    },
    handleProcessInfo: (state, action: PayloadAction<any | undefined>) => {
      state.processInfo = action.payload || null;
    },
  },
});

export const {
  handlePrefix,
  handleProcessId,
  handleProcessName,
  handleProcessInfo
} = bpmnSlice.actions;

export default bpmnSlice.reducer;
