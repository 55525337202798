/**
 *  sessionStorage key 对应的值
 *  @param key
 *  @returns {*}
 */
function getSession(key: string) {
    const value: any = window.sessionStorage.getItem(key) || "";
    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

/**
 *  sessionStorage 存入key 对应的值
 *  @param key
 *  @returns value
 */
function setSession(key: string, value: any) {
    const result = (typeof value == 'object' ? JSON.stringify(value) : value.toString());
    window.sessionStorage.setItem(key, result)
}
/**
 *  sessionStorage 删除 key 及对应的值
 *  @param key
 */
function clearSession(key: string) {
    window.sessionStorage.removeItem(key)
}
/**
 *  sessionStorage 清除
 */
function clearAllSession() {
    window.sessionStorage.clear()
}

export {
    getSession,
    setSession,
    clearSession,
    clearAllSession
}