import { useHistory } from "react-router-dom";
import { useEffect, Component } from 'react';

import { ConfigProvider, notification } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import debounce from 'lodash/debounce';

import ViewRouter from './router/index'
import './App.scss';

import { store } from '@/store/index';
import { setUserState } from '@/store/modules/userSlice';
import { setIsMobile } from '@/store/modules/commonSlice';

// 全局设置日期为中文
import moment from 'moment';
import 'moment/locale/zh-cn';
import { getUserInfo } from "./utils/cache";
moment.locale('zh-cn');
// 配置主题色
ConfigProvider.config({
  theme: {
    primaryColor: '#2558E9',
  },
});

// 通知提醒框配置
notification.config({
  duration: 3
});

export default class App extends Component {
  constructor(props:any) {
    super(props);

    // 创建 debounce 包装后的方法
    this.handleResize = debounce(this.handleResize, 300); // 设置延迟时间（单位：毫秒）
  }
  componentDidMount() {
    // 设置用户初始值
    store.dispatch(setUserState(getUserInfo()));
    
    this.handleResize();
    // 监听窗口变化
    window.addEventListener('resize', this.handleResize);
    console.log("------------App------------");
  }

  componentWillUnmount() {
    console.log("------------销毁------------");
    // 销毁窗口变化监听
    window.removeEventListener('resize', this.handleResize);
  }
  // 窗口大小变化
  handleResize() {
    console.log("============窗口变化===")
    // 判断当前环境是否是移动端
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || (window.innerWidth <= 767);
    store.dispatch(setIsMobile(isMobile));
  }
  //捕获错误边界，在render时错误会执行
  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, "----错误边界--------", errorInfo)
    /**
     * 主动刷新界面
     * 1.异步加载组件，资源不会一下子全加载，而是你点到哪个界面就加载那个界面的资源。
     * 2.重新打包发布后hash值不一样，找不到旧的资源。
     **/
    if (String(error).includes('ChunkLoadError')) {
      window.location.reload();
    }
  }

  render() {
    return (
      <ConfigProvider locale={zh_CN}>
        <ViewRouter />
      </ConfigProvider>
    );
  }
}

function logErrorToMyService(error: any, errorInfo: any) {
  throw new Error("Function not implemented.");
}