import { get } from '@/service/request';
import { getUserInfo } from "@/utils/cache"
import { getSession, setSession } from "@/utils/session"

/**
 * 获取权限菜单
 * @param isReload 是否刷新页面 默认false
 * @param callback 回调函数 返回菜单列表
 * @returns 
 */
const getMenuTree = (callback: any = () => { }) => {
    // 校验用户是否登录
    if (!getUserInfo()) return;
    get("/blade-system/menu/tree").then((res) => {
        if (res.success) {
            setSession("menuTree", res.data);
            callback([...res.data]);
        } else {
            setSession("menuTree", []);
            callback([]);
        }
    }).catch((err) => {
        setSession("menuTree", []);
        callback([]);
    })
}
/**
 * 获取项目内权限菜单列表
 * @param id 项目id
 * @param callback 回调函数
 * @returns viod
 */
const getProjectMenuTree = (id: string, callback: any = () => []) => {
    // 校验用户是否登录
    if (!getUserInfo()) return;
    get("/blade-system/menu/tree", {}, {
        "project-id": id
    }).then((res) => {
        if (res.success && res.data.length > 0) {
            setSession("projectMenuTree", res.data);
            callback([...res.data]);
        } else {
            callback([]);
        }
    }).catch((err) => {
        callback([]);
    })
}
/**
 * 校验页面功能权限
 * @param type 功能类型
 * @param pathName 路由名称
 * @returns boolean
 */
const checkPower = (type: string, pathName: string = window.location.pathname) => {
    let access: any = [];//功能权限列表
    // 递归获取当前路由权限
    function getAccess(list: any) {
        list.filter((item: any) => {
            if (item.path == pathName) {
                access = item.access
                return true;
            } else if (item.children && item.children.length > 0) {
                getAccess(item.children);
            }
        });
    }
    try {
        let menuTree = [];// 菜单权限列表
        // /bim开头的是 菜单权限页面  /project开头的是项目菜单页面
        if (pathName.indexOf("/bim") == 0) {
            menuTree = getSession("menuTree") || [];
        } else if (pathName.indexOf("/project") === 0) {
            menuTree = getSession("projectMenuTree") || [];
        }
        getAccess(menuTree);
        // console.log(menuTree,access,pathName)
    } catch (error) {
        console.error("******校验页面功能权限异常******", pathName, type)
    }
    return access.indexOf(type) !== -1;
}

// 防抖
let timerId: any = null; // 创建一个标记用来存放定时器的返回值
const debounce = (callback: any, duration: any) => {
    // 每当用户输入的时候把前一个 setTimeout clear 掉
    if (timerId < duration) timerId && clearTimeout(timerId)
    // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
    timerId = setTimeout(() => {
        callback()
    }, duration)
}



export {
    getMenuTree,
    getProjectMenuTree,
    checkPower,
    debounce
};
