import { post } from '@/service/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { store } from '@/store/index';
import { getUrlParms } from '@/utils';
import { getExpandAndShow } from '@/pages/ProjectView/TaskKanBan/components/taskTreeTable/expand';


/**
 * 任务看板模块
 */


// 饼图数据
interface data {
  name: string, //状态名称
  data: number[] //数值
}
// 树状图数据
interface seriesData {
  name: string,//楼栋名称
  value: number | string//数值
}
// 看板初始值

// 看板请求参数
export interface reqParams {
  "projectId": string, //项目id
  "optionType": string, //选项类型
  "modelName": string[], //清单
  "comment": string[], //描述
  "name": string[], //工序（任务）
  "status": string[], //施工条件（状态）
  "owner": string[], //负责人
  "type": string[], //专业
  "labor": string[], //工种
  "roomType": string[], //户型
  "building": string[], //楼栋
  "buildingUnit": string[], //单元
  "floor": string[], //楼层
  "roomNo": string[], //户号
  "cell": string[], //房间
  "planStart": string, //计划-开始时间
  "planStartEnd": string, //计划-结束时间
  "planFinish": string, //计划-完成时间
  "planFinishEnd": string, //计划-完成时间
  "actualStart": string, //实际开始时间-开始
  "actualStartEnd": string, //实际开始时间-结束
  "actualFinish": string, //实际完成时间-开始
  "actualFinishEnd": string, //实际完成时间-结束
  "regionExpanded": string[], // 展开的区域编码
  "regionSelected": string, //选中的区域编码
  "statusSelected": string, //选中的状态 
  "ownerSelected": string, // 选中的负责人(id)
  "laborSelected": string,  //选中的工种
  "taskSelected": string  // 选中的任务（工序id）
}
export interface state {
  // 结果页-筛选参数
  "resultParams": reqParams,
  "stateReset": Boolean, // 重置-任务状态分布
  "ownerReset": Boolean, // 重置-任务责任人分布
  "laborReset": Boolean,  // 重置-任务工种分布
  "regionWorkDayReset": Boolean,  //  重置-区域任务工日分布
  // 表格树数据
  "tableTreeData": any[],
  // 结果页-筛选数据
  "resultData": {
    "stateData": data[], //状态分布
    "ownerData": data[], //责任人分布
    "laborData": data[], //工种分布
    "regionWorkDayData": { //区域任务工日分布
      "legend": string[], //任务状态
      "series": seriesData[], //树状图数据
      "xaxis": string[] //楼栋
    },
    "taskListData": any[], //任务项
    "regionTaskTreeNode": any[], //区域树
  }
}
// 初始值
export const initialState: state = {
  "stateReset": false,
  "ownerReset": false,
  "laborReset": false,
  "regionWorkDayReset": false,
  "tableTreeData": [],
  "resultParams": {
    "projectId": "",
    "optionType": "",
    "modelName": [],
    "comment": [],
    "name": [],
    "status": [],
    "owner": [],
    "type": [],
    "labor": [],
    "roomType": [],
    "building": [],
    "buildingUnit": [],
    "floor": [],
    "roomNo": [],
    "cell": [],
    "planStart": "",
    "planStartEnd": "",
    "planFinish": "",
    "planFinishEnd": "",
    "actualStart": "",
    "actualStartEnd": "",
    "actualFinish": "",
    "actualFinishEnd": "",
    "regionExpanded": [],
    "regionSelected": '',
    "statusSelected": '',
    "ownerSelected": '',
    "laborSelected": '',
    "taskSelected": ''
  },
  "resultData": {
    "stateData": [],
    "ownerData": [],
    "laborData": [],
    "regionWorkDayData": {
      "legend": [],
      "series": [],
      "xaxis": []
    },
    "taskListData": [],
    "regionTaskTreeNode": []
  }
};



// 内置了thunk插件，可以直接处理异步请求
/**
 * 获取看板结果页数据
 * @param payload 接口请求参数（结构同resultParams）
 * @param regionType 展开到x层级 默认不展开 有值则展开
 * @returns 
 */
export const getResultData = (payload: reqParams, regionType: string = "") => (dispatch: any) => {
  console.log("---------结果页请求参数------", payload, regionType)
  // 更新请求参数
  dispatch(setResultParams(payload))
  // 请求数据
  post("/justtruth-region-task/board/options_results", payload).then((res) => {
    if (res.success) {
      // 一级筛选选择区域选项后联动表格树展开
      if (regionType) {
        let expandedKeys = getExpandAndShow(res.data.regionTaskTreeNode, regionType);
        // 更新请求参数
        dispatch(setResultParams({
          ...payload,
          regionExpanded: expandedKeys
        }))
      }
      /**
       * 查询当前点击项结果页数据时，接口不返回当前点击项的数据，故不更新当前点击项数据。
       */
      // 获取上次结果页数据
      let { resultData } = store.getState().taskBoard;
      // 结果页数据
      let resData = res.data;
      switch (payload.optionType) {
        case "statusSelected"://选中的状态
          resData.stateData = resultData.stateData;
          break;
        case "ownerSelected"://选中的负责人(id)
          resData.ownerData = resultData.ownerData;
          break;
        case "laborSelected"://选中的工种
          resData.laborData = resultData.laborData;
          break;
        case "taskSelected"://选中的任务（工序id）
          resData.taskListData = resultData.taskListData;
          break;
      }
      dispatch(setResultData(resData));//设置结果页数据
      //选中的区域编码
      if (payload.optionType != "regionSelected") {
        //设置表格数数据
        dispatch(setTableTreeData(res.data.regionTaskTreeNode));
      }
    }
  })
};

// 重置state，且重新请求结果页接口
export const resetState = () => (dispatch: any) => {
  console.log("---------切片-------", slice.getInitialState())
  var data: any = { ...initialState };
  data.resultParams = {
    ...data.resultParams,
    projectId: getUrlParms("projectId")
  };
  dispatch(setInitialState(data))
  dispatch(getResultData(data.resultParams))
};


// 创建一个 Slice（切片）
export const slice = createSlice({
  // 命名空间，name会作为action type的前缀
  name: 'taskBoard',
  // 初始化状态
  initialState,
  // 1、定义reducer更新状态的函数
  // 2、组件中dispatch使用的action函数
  reducers: {
    // 设置state
    setInitialState(state, { payload }) {
      return payload;
    },
    // 设置请求参数
    setResultParams(state, { payload }) {
      state.resultParams = payload;
    },
    // 设置表格数数据
    setTableTreeData(state, { payload }) {
      state.tableTreeData = payload;
    },
    // 设置结果页数据
    setResultData(state, { payload }) {
      state.resultData = payload;
    },
    setResetBar(state, { payload }) {
      state.regionWorkDayReset = payload.show
    },
    setReset(state, { payload }) {
      console.log(payload);
      if (payload.type === 1) {
        state.stateReset = payload.show
      } else if (payload.type === 2) {
        state.ownerReset = payload.show
      } else if (payload.type === 3) {
        state.laborReset = payload.show
      }
      if (payload.show) {
        state.regionWorkDayReset = payload.show
      }
    }
  },
  // extraReducers 字段让 slice 可以处理在别处定义的 actions， 
  // 包括由 createAsyncThunk或其他slice生成的actions。
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(xxx.pending, (state) => {
  //       console.log("🚀 ~ 进行中！")
  //     })
  //     .addCase(xxx.fulfilled, (state) => {
  //       console.log("🚀 ~ 已完成！")
  //     })
  // },
});


// 导出action函数
export const { setResultParams, setTableTreeData, setResetBar, setResultData, setReset, setInitialState } = slice.actions;
// 导出reducer，创建store
export default slice.reducer;