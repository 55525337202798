import {getUserInfo, setUserInfo} from "./cache";
import {getStorage, setStorage} from "./storage";


/**
 * 登录成功
 * @param data 用户信息
 */
function loginFn(data: any) {
    setUserInfo(data);//缓存用户信息
    // 跳转页面
    let url: any = getStorage("goBackUrl") || '/bim/message';
    window.location.href = url;
}


/**
 * 获取地址栏参数
 * @param variable 参数名
 * @returns
 */
function getUrlParms(variable: string): string {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return decodeURI(pair[1]);
        }
    }
    return "";
}

// 字节大小转化
function getfilesize(size: number) {//把字节转换成正常文件大小
    if (!size) return "";
    var num = 1024.00; //byte
    if (size < num)
        return size + "B";
    if (size < Math.pow(num, 2))
        return (size / num).toFixed(2) + " KB"; //kb
    if (size < Math.pow(num, 3))
        return (size / Math.pow(num, 2)).toFixed(2) + " MB"; //M
    if (size < Math.pow(num, 4))
        return (size / Math.pow(num, 3)).toFixed(2) + " G"; //G
    return (size / Math.pow(num, 4)).toFixed(2) + " T"; //T
}


/**
 * 获取滚动条宽度
 * className 类名
 * @returns number
 */
function getScrollbarWidth(className: string): number {
    try {
        let scrollDiv: any = document.getElementsByClassName(className)[0] || {};
        let scrollbarWidth = 0;
        if ((scrollDiv.scrollHeight > scrollDiv.clientHeight) || (scrollDiv.offsetHeight > scrollDiv.clientHeight)) {
            scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
        }
        return scrollbarWidth;
    } catch (error) {
        return 0;
    }
}

/**
 * 导出excel表格
 * @param file 二进制文件流
 * @param name 文件名称
 */
const exportExcel = (file: any, name: string) => {
    const link = document.createElement('a');
    // xls类型: application/vnd.ms-excel
    // xlsx类型：application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8
    const href = URL.createObjectURL(new Blob([file], {type: 'application/vnd.ms-excel'}));
    link.download = name;
    link.href = href;
    link.style.display = 'none'; //隐藏a标签 直接调用a标签的点击事件
    link.click();
    URL.revokeObjectURL(href);// 释放内存
    document.body.removeChild(link);// 移除a标签
}

/**
 * 按身份证号码获取性别
 * @idNumber 身份证号码
 * @return 男女 异常（身份证号码为空或长度、格式错误）：""
 */
const getGenderByIdNumber = (idNumber: string) => {
    if (!idNumber || idNumber.length != 15 && idNumber.length != 18) {
        return "";
    }
    let genderCode = ""; // 性别代码
    if (idNumber.length == 18) { // 二代身份证号码长度为18位（第17位为性别代码）
        genderCode = idNumber.charAt(16);
    } else if (idNumber.length == 15) { // 一代身份证号码长度为15位（第15位为性别代码）
        genderCode = idNumber.charAt(14);
    }
    // 两代身份证号码的性别代码都为男奇女偶
    if (parseInt(genderCode) % 2 == 0) {
        return '女';
    }
    return '男';
}

/**
 * 将number保留bitNum小数，四舍五入 不补0
 * @param number 数值
 * @param bitNum 保留n位小数(不传值默认保留两位)
 * @returns {string}
 */
const changeDecimal = (number: string, bitNum: number = 2): string | number => {
    var f_x = parseFloat(number);
    if (isNaN(f_x)) {
        f_x = 0;
    }
    return parseFloat(f_x.toFixed(bitNum));
}


/**
 * 将number保留bitNum小数，不够补0
 * @param number 数值
 * @param bitNum 保留n位小数
 * @returns {string}
 */
const changeDecimalCompletion = (number: string, bitNum: number = 2): string => {
    var f_x = parseFloat(number);
    if (isNaN(f_x)) {
        number = "0";
    }
    var s_x = number.toString();
    var pos_decimal = s_x.indexOf('.');
    //没有小数点的加上小数点
    if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        if (bitNum != 0) {
            s_x += '.';
        } else {
            //没有小数点还要保留0位小数
            return s_x;
        }
    }
    if (s_x.length <= pos_decimal + bitNum) {
        //eg:122 保留2位小数
        //return 122.00
        while (s_x.length <= pos_decimal + bitNum) {
            s_x += '0';
        }
    } else {
        //eg:1.222222  保留2位小数
        //return 1.22
        s_x = s_x.substring(0, pos_decimal + bitNum + 1)
    }
    return s_x;
}

/**
 * Input输入框限制小数位数
 * @param e input原生对象
 * @param size 小数位数
 * @returns
 */
const setInputDecimalSize = (e: any, size: number = 2) => {
    var {value} = e.target;
    // 将中文。转化为.
    if (value.indexOf("。") != -1) {
        value = value.replaceAll("。", ".")
    }
    // 输入多个.时只保留一个
    if ((value.split(".").length - 1) > 1) {
        let index = value.lastIndexOf(".");
        value = value.slice(0, index).concat(value.slice(index + 1));
        ;
    }
    // 首位为.时自动补0
    if (value.indexOf(".") == 0) {
        value = "0" + value
    }
    // 为空时，默认给0
    if (value == "") {
        value = "0"
    }
    const tempArr = value.split('.');
    const reg = /[^\d{1,}\.\d{1,}|\d{1,}]/g;
    let result: any = 0;
    if (tempArr[1]?.length) {
        result = `${tempArr[0]}.${tempArr[1].slice(0, size)}`.replace(reg, '');
    } else {
        result = value.replace(reg, '');
    }
    if (result) {
        let number = result.split(".");
        // 00.x 将字符串number转化为小数0.x
        if (number[1]?.length) {
            result = parseFloat(number[0]) + (value.indexOf("。") != -1 ? '.' : '.') + number[1];
        }
        // 00将字符串转化为小数0
        else if (number.length == 1) {
            result = parseFloat(number[0]);
        }
    }
    console.log(value, result)
    return result;
}

/**
 * 获取任务表格列缓存
 * 根据用户缓存表格列行为记录
 * @returns {any[]}
 */
const getTaskColumns = (): any[] => {
    let defaultTaskColumns = [
        {title: '楼栋', style: {width: "65px"}, dataIndex: 'building', checked: true, disabled: false},
        {title: '单元', style: {width: "65px"}, dataIndex: 'buildingUnit', checked: true, disabled: false},
        {title: '楼层', style: {width: "65px"}, dataIndex: 'floor', checked: true, disabled: false},
        {title: '户号', style: {flex: 5}, dataIndex: 'roomNo', checked: true, disabled: false},
        {title: '户型', style: {flex: 5}, dataIndex: 'roomType', checked: true, disabled: false},
        {title: '房间', style: {flex: 5}, dataIndex: 'cell', checked: true, disabled: false},
        {title: '分项工程', style: {width: "90px"}, dataIndex: 'type', checked: true, disabled: false},
        {title: '清单', style: {flex: 8}, dataIndex: 'modelName', checked: true, disabled: true},
        {title: '特征描述', style: {flex: 8}, dataIndex: 'quotaDescribes', checked: true, disabled: false},
        {title: '工序成本', style: {flex: 8}, dataIndex: 'quotaName', checked: true, disabled: false},
        {title: '工序', style: {flex: 8}, dataIndex: 'name', checked: true, disabled: true},
        {title: '工种', style: {width: "90px"}, dataIndex: 'labor', checked: true, disabled: false},
        {title: '工程量', style: {width: "90px"}, dataIndex: 'amount', checked: true, disabled: true},
        {title: '单位', style: {flex: 2}, dataIndex: 'unit', checked: true, disabled: true},
    ];
    let userInfo = getUserInfo();
    let key = `task_columns_${userInfo && userInfo.userId}`;
    let taskColumns = getStorage(key);
    if (taskColumns) {
        return taskColumns;
    } else {
        setStorage(key, defaultTaskColumns)
        return defaultTaskColumns;
    }
}

/**
 * 金额币种显示设置
 */
const setPriceSymbol = (price: any, symol: string = "") => {
    if (price > 0) {
        return '+' + symol + price;
    } else if (price < 0) {
        return '-' + symol + price.toString().substring(1);
    } else {
        return symol + price;
    }
}
/**
 * 判断两个Json对象的值是否相同
 */
const areObjectsEqual = (obj1: any, obj2: any) => {
    // 如果两个对象不是同类型，直接返回 false
    if (typeof obj1 !== typeof obj2) return false;

    // 如果对象为空，并且两个对象都是 null 或 undefined，则它们相等
    if (obj1 === null || obj1 === undefined) {
        return obj2 === null || obj2 === undefined;
    }

    // 如果两个对象都是数组，则递归比较它们的元素
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;
        for (let i = 0; i < obj1.length; i++) {
            if (!areObjectsEqual(obj1[i], obj2[i])) return false;
        }
        return true;
    }

    // 如果两个对象都是对象，则递归比较它们的属性
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!obj2.hasOwnProperty(key) || !areObjectsEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    }

    // 对于基本类型，直接比较它们的值
    return obj1 === obj2;
}

export {
    loginFn,
    getUrlParms,
    getfilesize,
    getScrollbarWidth,
    exportExcel,
    getGenderByIdNumber,
    changeDecimal,
    changeDecimalCompletion,
    setInputDecimalSize,
    getTaskColumns,
    setPriceSymbol,
    areObjectsEqual
}