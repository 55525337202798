import { store } from '@/store/index';
import { setUserState } from '@/store/modules/userSlice';

import { getSession, setSession, clearSession, clearAllSession } from "@/utils/session"
import { getStorage, setStorage, clearStorage, clearAllStorage } from "@/utils/storage"


/**
 * 获取用户缓存信息
 * 同一账号 同一浏览器多个窗口对应多个团队，使用session存储各个团队的信息。实现各个团队的数据隔离和功能展示。
 * storage、session优先使用session缓存。
 * @returns 当前团队用户信息
 */
const getUserInfo = () => {
    let result = getSession("userSession") || getStorage("userStorage");
    if (!getSession("userSession") && result) {
        setSession("userSession", result)
    }
    return result;
}

// 设置用户缓存信息
const setUserInfo = (data: any) => {
    setStorage("userStorage", data);//设置缓存
    setSession("userSession", data);//设置会话
    store.dispatch(setUserState(data));//设置redux值
}

// 清除用户缓存信息
const clearUserInfo = () => {
    clearStorage("userStorage");
    clearSession("userSession");
}


// 清除所有缓存
const clearAllCache = () => {
    clearAllStorage();
    clearAllSession();
}

export {
    getUserInfo,
    setUserInfo,
    clearUserInfo,
    clearAllCache
}