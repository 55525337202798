import RouterView from './router-view'  // 封装好的 Router

import routes from './config' // 路由列表，有其它的路由列表页面可以继续引入

const ViewRouter = () => {
    return (
        <>
            <RouterView route={routes} />
        </>
    )
}

export default ViewRouter
