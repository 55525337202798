import {
    Switch,
    Redirect,
    Route
} from "react-router-dom"; // 引入 react-router-dom
import { lazy } from 'react'
import { Suspense } from 'react' // Suspense 配合前面的 laze() 使用，不加上会报错
const ErrorPage = lazy(() => import('../components/NotFound/index'));

const RouterView = (props: any) => {
    let { route } = props // 拿到index.jsx页面传过来的 路由列表
    return (
        <Suspense fallback={<div className="bim_loading">Loading...</div>}>
            <Switch>
                {
                    route.map((item: any, index: number) => {
                        return <Route key={index} exact={item.path == '/'} path={item.path} render={(props) => {
                            return <item.component route={item.routes} {...props} />
                        }}></Route>
                    })
                }
                <Route component={ErrorPage} />
            </Switch>
        </Suspense>
    )
}

export default RouterView
